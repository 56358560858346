<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>
<script>
import { Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const content = `

BEEVEST SECURITIES LIMITED

The Company is licensed by the Securities and Futures Commission of Hong Kong (“SFC”) to engage in Type 1 (dealing in securities), Type 4 (advising on securities) regulated activities under the Securities and Futures Ordinance (Chapter 571 of the Laws of Hong Kong)[ Please refer to the SFC website at www.sfc.hk for updated details about the Company’s licensing position.].

The Company’s Central Entity number is ABM126 and its registered address is Units 1013 & 1015, 10/F, Leighton Centre, 77 Leighton Road, Causeway Bay, Hong Kong.

Agency Agreement Letter

This Agency Agreement Letter (the “Letter”), together with the following documents, form a written legal agreement (the “Agreement”) that defines the relationship between Beevest Securities Limited (“BVS”) and the client (hereafter the “Client”):

1.现金客户开户表，客户协议书及其附表1风险披露声明；

2.专业投资者评估表及专业投资者风险知会；

3.衍生产品风险披露声明及认识一般衍生产品；以及

4.客户协议书中提到的其它相关文件。


The Client should read this Letter and the Risk Disclosure Schedule Regarding Securities and Derivatives Transactions carefully to ensure that they only contain terms and conditions to which the Client is agreeable.


Appointment of BVS as Agent of Client


The Client expressly appoints BVS to act as its agent for the sole purpose of entering into securities and derivatives transactions (“Transactions”) on behalf of the Client from time to time.  In respect of any such Transactions, the Client authorizes BVS to enter into the Transactions on behalf of the Client, including but without limitation, to execute any contractual documentation (“Contracts”) in connection with the Transactions on behalf of the Client.  The Client acknowledges and agrees that BVS shall at all times be entering into such Transactions in the capacity of agent for and on behalf of the Client, and the Client agrees to be fully bound by the terms and conditions contained in any of the Contracts as if the Client had entered into such Contracts itself.    

Governing Law

This Agreement is governed by the laws of Hong Kong.  All disputes arising under or in relation to this Agreement and/or from any Transactions made pursuant hereto shall be subject to the exclusive jurisdiction of the courts of Hong Kong to which both the Client and BVS irrevocably submit, provided that this shall not prevent BVS from bringing an action in relation to this Agreement or any such Transactions in any court of any other jurisdiction.

If you have any queries, please feel free to contact [relevant sales’ contact details]

Yours sincerely

___________________________

Authorised signature

For and on behalf of

BEEVEST SECURITIES LIMITED



Agreed and accepted by:

___________________________

Authorised signature

For and on behalf of

Name: 

Date:
`.replace(/\r?\n/g, "<br />");
export default {
  name: "baal",
  components: {
    [Button.name]: Button
  },
  data() {
    return {
      content
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        bwm_bvs: "是"
      });
      if (res) {
        this.$router.back();
      }
    }
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "协议详情");
      store.commit("setStepsShow", false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.bwm_bvs === "是";
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm
    };
  }
};
</script>

<style lang="less">
.appendix {
  margin-top: @space-2;
  text-align: left;

  .content {
    padding: @space-2;
    background: @contentColor;
    font-size: @font-size-2;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
}
</style>
